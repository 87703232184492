import { UntrackedBudgetId } from '@og-shared/types';
import { BudgetDocument, BudgetFrequency } from '../types/budget';
import { GroupDocument } from '../types/group';
import { currencyNumber } from './currency-utils';
import { countFillDays } from './date-utils';
import { getTodayString } from './today';
import { IGNORE_BUDGET_ID, UNCATEGORIZED_BUDGET_ID } from './consts';

export function calcWeeklyFundingAmount(
  budget: Pick<
    BudgetDocument,
    | 'per_year'
    | 'next_transaction_date'
    | 'date_start'
    | 'date_end'
    | 'budget'
    | 'available'
    | 'fill_amount'
    | 'is_goal'
    | 'type'
  >,
  fill_day: GroupDocument['fill_day']
) {
  // only used in budget.ts reducer when editing a budget
  // to calculate the required weekly fill
  const perYear = budget.per_year;
  const date = budget.next_transaction_date;
  const todayString = getTodayString();
  if (perYear === 0) {
    if (date) {
      const start = budget.date_start || todayString;
      const end = budget.date_end || date;
      const number_of_fill_days = countFillDays(start, end, fill_day);
      const needed = budget.budget - budget.available;
      const weekly_fill = currencyNumber(needed / number_of_fill_days);
      return weekly_fill;
    } else {
      return budget.fill_amount;
    }
  } else if (budget.is_goal) {
    return budget.fill_amount;
  } else if (budget.budget && perYear) {
    // @todo replace weekly with yearly?
    // everything should be yearly to minimize rounding errors
    // or should it? yearly can't be divided evenly into weeks, so how do we ensure it always has enough - round down for income - round up for expenses.
    const weeklyAmount = calcFrequencyFromBudget({
      budget,
      frequency: 52,
      todayString,
    });
    return weeklyAmount;
  } else {
    return 0;
  }
}

export function calcFrequencyFromBudget({
  budget,
  frequency,
  todayString,
}: {
  budget: Pick<
    BudgetDocument,
    'per_year' | 'budget' | 'fill_amount' | 'date_start' | 'date_end' | 'type'
  >;
  frequency: BudgetFrequency | 365;
  todayString: string;
}) {
  // @todo use this to calc overview page - update unit tests
  if (!budget) return 0;
  if (!budget.budget || !budget.per_year) {
    if (!budget.fill_amount) return 0;
    // fill_amount is weekly - so it needs to be converted to whatever frequency
    return budget.fill_amount * (52 / frequency);
  }
  const includeInCashFlow = shouldIncludeBudgetBasedOnStartAndEndDates(
    budget,
    todayString
  );
  if (!includeInCashFlow) {
    // console.log("don't include in cash flow", budget);
    return 0;
  }
  if (frequency === budget.per_year) {
    return budget.budget;
  }
  const notRounded = budget.budget * (budget.per_year / frequency);
  if (budget.type === 'INCOME') {
    const roundDown = Math.floor(notRounded);
    return roundDown;
  } else {
    const roundedUp = Math.ceil(notRounded);
    return roundedUp;
  }
}

export function shouldIncludeBudgetBasedOnStartAndEndDates(
  budget: Pick<BudgetDocument, 'date_start' | 'date_end'>,
  date: string
) {
  // same function in functions/src/cron/fill-budgets.ts
  if (budget.date_start && date < budget.date_start) {
    // don't include if today is before start date
    return false;
  }
  if (budget.date_end && date > budget.date_end) {
    // don't include if today is after the end date
    return false;
  }
  return true;
}

export function getAllBudgetFrequencies() {
  const arrayOfAllFrequencies = <T extends BudgetFrequency[]>(
    array: T & ([BudgetFrequency] extends [T[number]] ? unknown : 'Invalid')
  ) => array;

  // const missing = arrayOfAllFrequencies([1, 2]); // error
  // const extra = arrayOfAllFrequencies([0, 1, 2, 4, 6, 12, 24, 26, 52, 365]); // error
  return arrayOfAllFrequencies([0, 1, 2, 4, 6, 12, 24, 26, 52]); // compiles
}

export function sortBudgetsFromPlan(
  a: Pick<
    BudgetDocument,
    'per_year' | 'budget' | 'fill_amount' | 'date_start' | 'date_end' | 'type'
  >,
  b: Pick<
    BudgetDocument,
    'per_year' | 'budget' | 'fill_amount' | 'date_start' | 'date_end' | 'type'
  >
) {
  const todayString = getTodayString();
  return (
    calcFrequencyFromBudget({
      budget: b,
      frequency: 1,
      todayString,
    }) -
    calcFrequencyFromBudget({
      budget: a,
      frequency: 1,
      todayString,
    })
  );
}

export function sortBudgetsByDueDate(
  a: Pick<
    BudgetDocument,
    | 'per_year'
    | 'budget'
    | 'fill_amount'
    | 'date_start'
    | 'date_end'
    | 'type'
    | 'next_transaction_date'
  >,
  b: Pick<
    BudgetDocument,
    | 'per_year'
    | 'budget'
    | 'fill_amount'
    | 'date_start'
    | 'date_end'
    | 'type'
    | 'next_transaction_date'
  >
) {
  if (
    a.next_transaction_date === b.next_transaction_date ||
    (!a.next_transaction_date && !b.next_transaction_date)
  ) {
    return sortBudgetsFromPlan(a, b);
  }
  if (!a.next_transaction_date) {
    return 1;
  }
  if (!b.next_transaction_date) {
    return -1;
  }
  return a.next_transaction_date > b.next_transaction_date ? 1 : -1;
}

export function budgetNotTracked(budget_id: string) {
  const notTracked: UntrackedBudgetId[] = [
    IGNORE_BUDGET_ID,
    UNCATEGORIZED_BUDGET_ID,
  ];
  return notTracked.indexOf(budget_id as any) !== -1;
}

export function budgetIsTracked(budgetId: string) {
  const notTracked: UntrackedBudgetId[] = [
    IGNORE_BUDGET_ID,
    UNCATEGORIZED_BUDGET_ID,
  ];
  return notTracked.indexOf(budgetId as any) === -1;
}
